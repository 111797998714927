<template>
	<div class="tabbar">
		<van-tabbar v-model="active" :fixed="false"
			:active-color="$store.state.district.type == 1 ? '#3377FF' : '#FF6969'">
			<van-tabbar-item :icon="active == key ? vo.iconActive : vo.icon" v-for="(vo, key) in menu" replace
				:dot="vo.isDot == true && isShowDot == true" :to="vo.route">
				{{ vo.title }}
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
const cityMenu = [
	{
		title: '首页',
		icon: require('@/assets/img/tabbar_city1.png'),
		iconActive: require('@/assets/img/tabbar_current_city1.png'),
		route: { name: 'Index' },
		isDot: false
	},
	{
		title: '义警圈',
		icon: require('@/assets/img/tabbar_city6.png'),
		iconActive: require('@/assets/img/tabbar_current_city6.png'),
		route: { name: 'CircleFriends' },
		isDot: false
	},
	{
		title: '打卡',
		icon: require('@/assets/img/tabbar_city3.png'),
		iconActive: require('@/assets/img/tabbar_current_city3.png'),
		route: { name: 'SignIn' },
		isDot: false
	},
	{
		title: '消息',
		icon: require('@/assets/img/tabbar_city4.png'),
		iconActive: require('@/assets/img/tabbar_current_city4.png'),
		route: { name: 'Message' },
		isDot: true
	},
	{
		title: '我的',
		icon: require('@/assets/img/tabbar_city5.png'),
		iconActive: require('@/assets/img/tabbar_current_city5.png'),
		route: { name: 'User' },
		isDot: false
	}
];

const districtMenu = [
	{
		title: '首页',
		icon: require('@/assets/img/tabbar_district1.png'),
		iconActive: require('@/assets/img/tabbar_current_district1.png'),
		route: { name: 'Index' },
		isDot: false
	},
	{
		title: '义警圈',
		icon: require('@/assets/img/tabbar_city6.png'),
		iconActive: require('@/assets/img/tabbar_current_district6.png'),
		route: { name: 'CircleFriends' },
		isDot: false
	},
	{
		title: '打卡',
		icon: require('@/assets/img/tabbar_district3.png'),
		iconActive: require('@/assets/img/tabbar_current_district3.png'),
		route: { name: 'SignIn' },
		isDot: false
	},
	{
		title: '消息',
		icon: require('@/assets/img/tabbar_district4.png'),
		iconActive: require('@/assets/img/tabbar_current_district4.png'),
		route: { name: 'Message' },
		isDot: true
	},
	{
		title: '我的',
		icon: require('@/assets/img/tabbar_district5.png'),
		iconActive: require('@/assets/img/tabbar_current_district5.png'),
		route: { name: 'User' },
		isDot: false
	}
];

export default {
	data() {
		return {
			cityMenu: cityMenu,
			districtMenu: districtMenu
		};
	},
	created() {
		this.upadteActive();
	},
	activated() {
		this.upadteActive();
	},
	computed: {
		isShowDot() {
			let isShow = false;

			for (let vo in this.$store.state.group.group) {
				if (this.$store.state.group.group[vo].unread) {
					isShow = true;
					break;
				}
			}
			
			return isShow || this.$store.state.letter.unreadTotal > 0;
		},
		menu() {
			return this.$store.state.district.type == 1 ? cityMenu : districtMenu;
		},
		active: {
			get() {
				return this.$store.state.tabbar.active;
			},
			set(active) {
				this.$store.commit('tabbar/setActive', active);
			}
		}
	},
	methods: {
		upadteActive() {
			this.menu.forEach((item, key) => {
				if (this.$route.name == item.route.name) {
					this.$store.commit('tabbar/setActive', key);
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.tabbar {
	position: relative;
	z-index: 9999;
	.tabbar-space {
		height: 50px;
	}
}
</style>
